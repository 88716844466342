jQuery(document).ready(function ($) {

	var md = new MobileDetect(window.navigator.userAgent);
	var isMobile = ((md.mobile() || md.phone() || md.tablet()) != null);

	(function (settings) {

		function columnSort(a, b) {
			return b.value - a.value;
		}

		var data = settings.data,
			values = data[1].values.length,
			labels = settings.labels,
			columns = [],
			groupedCircles = [],
			showLines = true;

		for (var i = 0; i < values; i++) {
			columns[i] = [];
			for (var j = 1, n = data.length; j < n; j++) {
				columns[i].push({
					id: j - 1,
					ref: data[j],
					value: data[j].values[i]
				});
			}
			columns[i].sort(columnSort);
			var x = 41 * i + 32;
			columns[i].map(function (li, n) {
				li.x = x;
				li.y = 41 * n + 22;
				if (!groupedCircles[li.id]) {
					groupedCircles[li.id] = [];
				}
				groupedCircles[li.id].push(li);
			});
		}
		var allCircles = [].concat.apply([], columns);

		var box = d3.select('#diversify_widget'),
			svg = box.select('svg'),
			list = box.select('ul');

		box.style('display', 'block');

		function isAllSelected() {
			for (var i = 1, n = data.length; i < n; i++) {
				if (!data[i].selected && !data[i].forceSelect) return false;
			}
			return true;
		}

		function countSelected() {
			var count = 0;
			for (var i = 1, n = data.length; i < n; i++) {
				if (data[i].selected || data[i].forceSelect || data[i].hover) count++;
			}
			return count;
		}

		function onListClick(li) {
			if (li.selectAll) {
				var sel = !data[0].selected;
				data.forEach(function (l) {
					l.selected = sel || l.forceSelect;
					l.hover = sel;
				});
			} else {
				li.selected = !li.selected;
				li.hover = li.selected;
			}
			data[0].selected = isAllSelected();
			update();
		}

		function onListHover(li, type) {
			if (li.selectAll) {
				data.forEach(function (l) {
					l.hover = type;
				});
			} else {
				li.hover = type;
			}
			update();
		}

		var listElements = list
			.selectAll('li')
			.data(data)
			.enter()
			.append('li')
			.text(function (li) {
				var nameLang = li.name.split(' | ');
				if ($('[data-last-column]').data('lang') == "es") return nameLang[0];
				else if ($('[data-last-column]').data('lang') == "it") return nameLang[1];

			})
			.classed('active', function (li) {
				return li.selected || li.forceSelect;
			})
			.on('click', onListClick)
			.call(function (selection) {
				if (settings.isMobile) {
					selection.on('touch', onListClick);
				} else {
					selection.on('mouseenter', function (li) {
						onListHover(li, true);
					});
					selection.on('mouseleave', function (li) {
						onListHover(li, false);
					});
				}
			});

		listElements
			.append('span')
			.style('background-color', function (li) {
				return li.color || 'transparent';
			});

		svg
			.selectAll('circle')
			.data(allCircles)
			.enter()
			.append('circle')
			.attr('fill', '#d0d0d0')
			.attr('cy', function (d) {
				return d.y;
			})
			.attr('cx', function (d) {
				return d.x;
			})
			.attr('r', 18);

		var valuesLength = data[1].values.length;
		svg
			.selectAll('text')
			.data(d3.range(valuesLength + 2))
			.enter()
			.append('text')
			.attr('class', 'legend')
			.attr('fill', '#000')
			.attr('pointer-events', 'none')
			.attr('text-anchor', 'middle')
			.attr('y', function (d) {
				if (valuesLength + 1 == d) {
					return (settings.data.length - 1) * 41 + 35;
				}
				return (settings.data.length - 1) * 41 + 21;
			})
			.attr('x', function (d) {
				if (valuesLength <= d) {
					return 41 * valuesLength + 48;
				}
				return 41 * d + 33;
			})
			.text(function (d) {
				var dataLastColumn = $('[data-last-column]').data('last-column').split(" - ");
				if (valuesLength == d) {
					return dataLastColumn[0]; //'10 años';
				} else if (valuesLength + 1 == d) {
					return dataLastColumn[1]; //'(annualizado)';
				}
				return settings.labels[d];
			});

		var groups = svg
			.selectAll('g')
			.data(groupedCircles)
			.enter()
			.append('g')
			.style('opacity', function (d) {
				return d[0].ref.selected || d[0].ref.forceSelect ? 1 : 0;
			});

		var lineFunction = d3.svg.line()
			.x(function (d) {
				return d.x;
			})
			.y(function (d) {
				return d.y;
			})
			.interpolate("linear");

		var lines = groups
			.append("path")
			.style('display', function (d) {
				return d[0].ref.selected || d[0].ref.forceSelect ? 'block' : 'none';
			})
			.attr('pointer-events', 'none')
			.attr("d", lineFunction)
			.attr("stroke", function (d) {
				return d[0].ref.color;
			})
			.attr("stroke-width", 4)
			.attr("fill", "none");

		var sumCircles;

		function onCircleClick(li) {
			li.selected = !li.selected;
			li.hover = li.selected;
			data[0].selected = isAllSelected();
			update();
		}

		function onCircleHover(li, type) {
			li.hover = type;
			update();
		}

		groups
			.selectAll('circle')
			.data(function (d) {
				return d;
			})
			.enter()
			.append('circle')
			.on('click', function (li) {
				onCircleClick(li.ref);
			})
			.call(function (selection) {
				if (settings.isMobile) {
					selection.on('touch', function (li) {
						onCircleClick(li.ref);
					});
				} else {
					selection.on('mouseenter', function (li) {
						onCircleHover(li.ref, true);
					});
					selection.on('mouseleave', function (li) {
						onCircleHover(li.ref, false);
					});
				}
			})
			.attr('fill', function (li) {
				return li.ref.color;
			})
			.attr('cy', function (d) {
				return d.y;
			})
			.attr('cx', function (d) {
				return d.x;
			})
			.attr('r', 18)
			.attr('stroke-opacity', 0.5)
			.attr('stroke-width', 5)
			.attr('stroke', function (li) {
				return li.ref.color;
			});

		var textGroup = groups
			.selectAll('g')
			.data(function (li) {
				return li;
			})
			.enter()
			.append('g')
			.attr('transform', function (li) {
				return 'translate(' + (li.x - 22) + ', ' + (li.y - 22) + ')';
			});

		textGroup
			.append('text')
			.attr('fill', '#fff')
			.attr('pointer-events', 'none')
			.attr('text-anchor', 'middle')
			.attr('y', 21)
			.attr('x', 22)
			.text(function (d) {
				return d.value.toString().replace(".", ",");
			});

		textGroup
			.append('text')
			.attr('fill', '#fff')
			.attr('pointer-events', 'none')
			.attr('text-anchor', 'middle')
			.attr('y', 34)
			.attr('x', 22)
			.text('%');

		var lastColumn = svg
			.append('g')
			.attr('transform', 'translate(' + (41 * columns.length + 25) + ', 0)');

		var lastValues = data.slice(1).sort(columnSort);
		var sumCircles;
		var sumCircles = lastColumn
			.selectAll('circle')
			.data(lastValues)
			.enter()
			.append('circle')
			.on('click', onCircleClick)
			.call(function (selection) {
				if (settings.isMobile) {
					selection.on('touch', onCircleClick);
				} else {
					selection.on('mouseenter', function (li) {
						onCircleHover(li, true);
					});
					selection.on('mouseleave', function (li) {
						onCircleHover(li, false);
					});
				}
			})
			.attr('fill', function (li) {
				return li.selected || li.forceSelect ? li.color : '#d0d0d0';
			})
			.attr('stroke-opacity', 0.5)
			.attr('stroke-width', function (li) {
				return li.selected || li.forceSelect ? 5 : 0;
			})
			.attr('stroke', function (li) {
				return li.color;
			})
			.attr('cy', function (d, i) {
				return 41 * i + 22;
			})
			.attr('cx', 22)
			.attr('r', 18);

		var textSumGroup = lastColumn
			.selectAll('g')
			.data(lastValues)
			.enter()
			.append('g')
			.style('display', function (li) {
				return li.selected || li.forceSelect ? 'block' : 'none';
			})
			.attr('transform', function (li, i) {
				return 'translate(0, ' + (41 * i) + ')';
			});

		textSumGroup
			.append('text')
			.attr('fill', '#fff')
			.attr('pointer-events', 'none')
			.attr('text-anchor', 'middle')
			.attr('y', 21)
			.attr('x', 22)
			.text(function (li) {
				return li.value.toString().replace(".", ",");
			});

		textSumGroup
			.append('text')
			.attr('fill', '#fff')
			.attr('pointer-events', 'none')
			.attr('text-anchor', 'middle')
			.attr('y', 34)
			.attr('x', 22)
			.text('%');

		function update() {
			showLines = (countSelected() <= 2);

			listElements
				.classed('active', function (li) {
					return li.selected || li.forceSelect || li.hover;
				});

			groups
				.style('opacity', function (d) {
					return d[0].ref.selected || d[0].ref.forceSelect || d[0].ref.hover ? 1 : 0;
				});

			lines
				.style('display', showLines ? 'block' : 'none');

			sumCircles
				.attr('fill', function (li) {
					return li.selected || li.forceSelect || li.hover ? li.color : '#d0d0d0';
				})
				.attr('stroke-width', function (li) {
					return li.selected || li.forceSelect || li.hover ? 5 : 0;
				});

			textSumGroup
				.style('display', function (li) {
					return li.selected || li.forceSelect || li.hover ? 'block' : 'none';
				});
		}

	})({
		isMobile: isMobile,
		startYear: 2008,
		data: [{
				name: 'Selecc. Todas | Seleziona tutto',
				selectAll: true
			},
			{
				name: 'Cartera mixta | Portafoglio', // Portfolio
				color: '#006496',
				values: [-20.9, 25.4, 18.9, 1.2, 10.7, 3.3, 16.2, 6.4, 10.3, 1.7, -1.6, -4.5],
				value: 5.8,
				forceSelect: true
			},
			{
				name: 'Renta Variable ME | Azioni ME', // EME
				color: '#582C83',
				values: [-50.8, 73.4, 27.5, -15.4, 16.8, -6.5, 11.8, -4.9, 14.9, 21.0, -9.9, -5.9],
				value: 2.7
			},
			{
				name: 'Materias primas | Materie prime', // Cmdty
				color: '#828838',
				values: [-32.3, 15.2, 24.9, -10.4, -2.6, -13.4, -5.5, -16.1, 15.1, -10.7, -6.8, 8.0],
				value: -5.1
			},
			{
				name: 'Renta Variable MD | Azioni MS', // DM Equities
				color: '#373741',
				values: [-37.2, 26.7, 20.1, -1.8, 14.7, 21.9, 20.1, 11, 11.4, 8.1, -3.6, -11.9],
				value: 6.7
			},
			{
				name: 'Deuda ME | Debito ME', // EMD
				color: '#649099',
				values: [-6.3, 25.4, 19.8, 12.1, 16.7, -10.6, 20.2, 12.8, 13.5, -4.0, 0.2, 0.4],
				value: 8.3
			},
			{
				name: 'REITS | REITs', // REITS
				color: '#B9B631',
				values: [-34.1, 23.5, 36.4, 10.9, 18.3, -1.3, 44.8, 13.9, 12.6, -4.0, 0.7, -4.6],
				value: 9.0
			},
			{
				name: 'Hedge Funds | Hedge Funds', // Hedge Funds
				color: '#625272',
				values: [-19.3, 9.9, 12,5, -5.8, 1.9, 2.1, 13.2, 7.3, 5.6, -6.9, -2.0, -4.0],
				value: 1.2
			},
			{
				name: 'Bonos HY | Obbligazioni HY', // HY bonds
				color: '#BDCBCA',
				values: [-23.1, 54.4, 22.8, 6.6, 17.8, 2.7, 13.9, 8.4, 17.7, -3.0, 0.8, -1.9],
				value: 9.3
			},
			{
				name: 'RF Gob | Obbligazioni gov.', // Govt bonds
				color: '#1C4572',
				values: [15.9, -0.6, 13.3, 9.9, 0.3, -8.4, 13, 7.7, 4.7, -5.8, 4.6, 3.8],
				value: 4.7
			},
			{
				name: 'Bonos IG | Obbligazioni IG', // IG bonds
				color: '#9D74B4',
				values: [-3.9, 15.5, 13.2, 7.8, 9.5, -4, 17.5, 7.4, 7.4, -4.2, 1.3, 0.8],
				value: 5.9
			},
			{
				name: 'Liquidez | Liquidità', // Cash
				color: '#576840',
				values: [5.7, 2.3, 1.1, 1.7, 1.2, 0.2, 0.3, 0.1, -0.1, -0.3, -0.3, -0.1],
				value: 1.1
			}
		],
		labels: ['2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '4T18']
	});
});